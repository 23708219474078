@import '../../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  } 

.inner-header-section{
    padding: 15px 165px;
    min-height: 750px;
    background: $primary;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            // max-width: 75%;
        }
    }
}
.topup-section{
    padding: 40px 165px;
    .form-box{
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        min-height: 450px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -20rem;
        padding: 3rem 0rem;
        .search-box{
            padding: 0rem 6rem;
            margin-bottom: 1rem;
            h2{
                font-size: 24px;
                font-weight: 700;
                position: relative;
                .pi{
                    font-size: 3rem;
                    color: $secondary;
                    position: absolute;
                    left: -70px;
                    top: -7px;
                    cursor: pointer;
                }
            }
            .search-field{
                position: relative;
                width: 100%;
                max-width: 300px;
                .pi-search{
                    margin-left: 6px;
                    z-index: 1;
                }
                .p-component{
                    border: 2px solid rgba(0, 82, 162, 0.24);
                    border-radius: 25px;
                    width: 100%;
                    height: 45px;
                }
                .p-dropdown-label{
                    padding-left: 2.7rem;
                    text-align: left;
                }
                .search-button{
                    position: absolute;
                    right: 4px;
                    border-radius: 30px;
                    padding: 11px 30px;
                    top: 3px;
                    background: $secondary;
                    border: none;
                    .p-button-label{
                        font-size: 14px;
                        font-weight: 400;
                    }
                    &:hover{
                        background: $primary;
                    }
                }
            }
            .info-section{
                .info-box{
                    padding: 5px 0px;
                    p{
                        margin: 0px;
                        font-family: $font-secondary;
                        color: $primary;
                        &.infoText{
                            font-style: italic;
                            position: relative;
                            display: flex;
                            align-items: center;
                            .info-icon{
                                background: $secondary;
                                border-radius: 50px;
                                color: $white;
                                display: block;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                                width: 20px;
                                margin-right:5px;
                                top: -1px;
                                position: relative;
                                .pi-info{
                                    font-size: 12px;
                                }
                            }
                        }
                        &.iccidText{
                            span{
                                font-weight: 500;
                            }
                        }
                    }
                    ul{
                        margin: 0px;
                        padding: 5px 0px;
                        li{
                            list-style: none;
                            display: inline-block;
                            margin-right: 12px;
                            color: $input-border;
                        }
                    }
                }
            }
        }
        .topup-table{
            .p-datatable{
                .p-datatable-thead{
                    > tr {
                        > th{
                            background: $primary;
                            padding: 1.5rem 1rem;
                            .p-column-title{
                                color: $white;
                                font-size: 15px;
                                font-weight: 600;
                            }
                            &:first-child{
                                padding: 1.5rem 4rem;
                            }
                        }
                    }
                }
                .p-datatable-tbody{
                    tr{
                        td{
                            border-bottom: 1px solid $quaternary;
                            p{
                                margin: 0px 0px 3px;
                                color: #222B45;
                                font-size: 14px;
                                font-weight: 500;
                            }
                            span{
                                font-size: 14px;
                                color: #8F9BB3;
                            }
                            &.first-column{
                                padding: 1rem 4rem;
                            }
                            button{
                                border-radius: 15px;
                                background: $secondary;
                                border: none;
                                padding: 0.75rem 2rem;
                                margin-left: 10px;
                                .p-button-label{
                                    color: $white;
                                    font-size: 13px;
                                    font-weight: 600;
                                }
                                &:hover{
                                    background: $primary;
                                }
                            }
                        }
                        &:last-child{
                            td{
                                border: none;
                            }
                        }
                    }
                    
                }
            }
        }
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1460px){
    .inner-header-section, .contact-form-section{
        padding: 0px;
        .header-text{
            p{
                max-width: 80%;
            }
        }
    }
    .inner-header-section{
        .header-text{
            h1{
                font-size: 42px;
            }
        }
    }
}
@media (max-width:960px){
    .inner-header-section{
        padding: 0px;
        min-height: auto;
        .header-text{
            margin-top: 2rem;
            margin-bottom: 5rem;
            p{
                max-width: 100%;
                font-size: 16px;
                line-height: 28px;
            }
            h1{
                font-size: 28px;
            }
        }
    }
    .contact-form-section{
        padding: 25px 20px;
        .form-box{
            padding: 2rem 1.5rem;
            margin-top: -8rem;
            h2{
                margin: 0px 0px 10px;
            }
        }
        .contact-box{
            padding: 1rem 1rem;
            .benefits-img{
                img{
                    width: 100%;
                    max-width: 80px;
                }
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .inner-header-section, .contact-form-section{
        padding: 0px;
        .header-text{
            h1{
                font-size: 34px;
            }
        }
    }
    .contact-form-section{
        padding: 25px 20px;
        .form-box{
            padding: 2rem 2rem;
        }
        .contact-box{
            padding: 1rem 1rem;
        }
    }
    .is-sticky .fixed-height {
        height: 90px;
    }
}

@media (min-width:1461px) and (max-width:1750px){
    .inner-header-section{
        padding: 0px;
    }
}