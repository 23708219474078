@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $btnprimary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimaryover;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    &.contact-header{
        .header-text{
            color: $white;
            margin-top: 6rem;
            h1{
                font-size: 48px;
                font-weight: 700;
                margin: 0px;
            }
            p{
                font-size: 20px;
                font-family: $font-secondary;
                font-weight: 400;
                line-height: 36px;
                margin: 25px auto;
                width: 100%;
                max-width: 75%;
            }
        }
    }
}
.contact-header{
    .is-sticky{
        .fixed-height {
            height: 70px;
        }
    }
}
.contact-form-section{
    padding: 40px 165px;
    .form-box{
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        min-height: 450px;
        border: 1px solid $quaternary;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -20rem;
        .contact_form{
            .p-component{
                border-radius: 8px;
                border: 1px solid $input-border;
                height: 50px;
                font-size: 16px;
                font-weight: 400;
                &.p-inputtextarea{
                    height: 135px;
                }
                &::placeholder {
                    color: $input-border;
                    font-weight: 400;
                  }
                  
                &::-ms-input-placeholder { 
                    color: $input-border;
                    font-weight: 400;
                  }
            }
            p{
                margin: 0px;
                font-size: 16px;
                font-family: $font-secondary;
            }
            .contact-button{
                @include button;
                .p-button-label{
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .custom-phoneno-field{
                .react-tel-input{
                    font-family: $font-secondary;
                    .form-control {
                        border-radius: 8px;
                        border: 1px solid $input-border;
                        height: 50px;
                        font-size: 16px;
                        font-weight: 400;
                        width: 100%;
                        font-family: $font-secondary;
                    }
                    .flag-dropdown{
                        border-radius: 8px 0 0 8px;
                        .selected-flag{
                            border-radius: 8px 0 0 8px;
                        }
                    }
                }
            }
        }
    }
    .contact-box{
        padding: 2rem 3rem;
        text-align: center;
        h3{
            font-size: 24px;
            font-weight: 700;
            margin: 10px 0px 20px;
        }
        p{
            font-size: 16px;
            font-family: $font-secondary;
            line-height: 25px;
            margin: 0px;
        }
        a{
            font-size: 16px;
            color: $btnprimaryover;
            font-weight: 700;
            font-family: $font-secondary;
            display: inline-block;
            border-bottom: 1px solid $white;
            &:hover{
                color: $btnprimary;
                border-bottom: 1px solid $primary;
            }
            &.faq-link{
                display: table;
                margin: 0px auto;
                position: relative;
                top: 10px;
            }
        }
    }
    .error-message{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}

@media (min-width: 1200px) and (max-width: 1460px) {
    .contact-header{
        .is-sticky{
            .fixed-height {
                height: 90px;
            }
        }
    }
}
@media (min-width: 961px) and (max-width: 1199px) {
    .inner-header-section.contact-header{
        .is-sticky{
            .fixed-height {
                height: 90px;
            }
        }
        .header-text {
            margin-top: 7rem !important;
            p{
                max-width: 90%;
            }
        }
    }
}
@media (max-width:960px){
    .inner-header-section.contact-header .header-text{
        h1{
            font-size: 28px !important;
        }
        p{
            max-width: 95%;
            font-size: 22px;
        }
    }
}