@import '../../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            // max-width: 65%;
        }
    }
}
.contact-form-section{
    padding: 40px 165px;
    .form-box{
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        min-height: 450px;
        border: 1px solid $quaternary;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -20rem;
        padding: 3rem 6rem;
        h2{
            font-size: 24px;
            font-weight: 700;
            position: relative;
            .pi{
                font-size: 3rem;
                color: $secondary;
                position: absolute;
                left: -70px;
                top: -7px;
                cursor: pointer;
            }
        }
        .esims-section{
            h3{
                font-size: 22px;
                font-weight: 600;
                margin: 1.5rem 0px 1rem;
            }
            p{
                font-size: 14px;
                font-family: $font-secondary;
            }
            .esim-box{
                border-radius: 15px;
                background: $white;
                box-shadow: 0px 0px 4px 0px rgba(0, 38, 77, 0.25);
                padding: 1.5rem;
                min-height: 325px;
                .user-edit-ul{
                    margin: 0px;
                    padding: 0px;
                    font-family: $font-base;
                    li{
                        list-style: none;
                        span{
                            margin: 0px;
                            font-size: 16px;
                            color: $secondary;
                            font-weight: 500;
                            margin-right: 12px;
                            &.active{
                                margin-right: 0px;
                                border-radius: 15px;
                                background: #2CAD28;
                                font-size: 13px;
                                font-weight: 500;
                                color: $white;
                                padding: 2px 12px;
                            }
                            &.inactive{
                                background: #BBBDBF;
                                color: #4C4C4C;
                                margin-right: 0px;
                                border-radius: 15px;
                                font-size: 13px;
                                font-weight: 500;
                                padding: 2px 12px;
                            }
                        }
                        .p-image{
                            position: relative;
                            top: 4px;
                        }
                        b{
                            color: $secondary;
                            font-weight: 500;
                        }
                        &.unknown-status{
                            padding: 3px 12px;
                            border-radius: 15px;
                            background: $primary;
                            span{
                                margin: 0px;
                                color: $white !important;
                                margin-right: 0px !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                }
                .id_p{
                    margin: 2px 0px 15px;
                    font-size: 15px;
                    font-weight: 500;
                }
                .progress-bar{
                    margin-top: 10px;
                    p{
                        margin: 5px 0px;
                        font-size: 16px;
                        font-weight: 400;
                        span{
                            font-weight: 600;
                        }
                    }
                    .p-progressbar{
                        height: 8px;
                        .p-progressbar-value{
                            background: $secondary;
                            .p-progressbar-label{
                                display: none;
                            }
                        }
                    }
                }
                .esim-not-installed{
                    border-radius: 20px;
                    box-shadow: 0px 0px 4px 0px rgba(0, 61, 112, 0.05);
                    background: #feebee;
                    margin: 15px 0px;
                    padding: 1rem 2rem;
                    text-align: center;
                    .pi{
                        color: $secondary;
                        font-size: 20px;
                    }
                    p{
                        font-size: 16px;
                        font-weight: 500;
                        margin: 8px 0px;
                        &.font-weight-light{
                            font-weight: 300;
                        }
                    }
                }
                .buttons-ul{
                    margin: 1.3rem 0px 0px;
                    padding: 0px;
                    li{
                        list-style: none;
                        width: 100%;
                        max-width: 48%;
                        button{
                            border-radius: 25px;
                            border: 0.5px solid $secondary;
                            background: rgba(255, 255, 255, 0.10);
                            width: 100%;
                            padding: 8px 1.2rem;
                            .p-button-label{
                                font-size: 15px;
                                font-weight: 600;
                                color: $secondary;
                            }
                            .p-button-icon{
                                color: $secondary;
                            }
                            &:hover{
                                background: $primary;
                                border: 0.5px solid $primary;
                                .p-button-label{
                                    color: $white;
                                }
                                .p-button-icon{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                &.not-installed-esim-box{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    .pi{
                        font-size: 3rem;
                        color: $secondary;
                    }
                    p{
                        font-size: 16px;
                        color: $secondary; 
                        font-weight: 600;
                    }
                }
                .status-info{
                    h2{
                        margin: 25px 0px 0px;
                        font-size: 20px;
                        color: $primary;
                    }
                }
            }
            &.esim-details-section{
                .esim-box{
                    min-height: auto;
                    padding: 1rem 1.5rem;
                    label{
                        font-size: 14px;
                        font-weight: 500;
                        .pi{
                            color: $secondary;
                            position: relative;
                            top: 1px;
                            font-size: 14px;
                            margin-left: 6px;
                        }
                    }
                    .p-input-icon-right{
                        .pi{
                            color: $secondary;
                            font-size: 16px;
                            &.pi-pencil{
                                font-size: 14px;
                            }
                        }
                    }
                    .p-inputtext{
                        border-radius: 15px;
                        border: 1px solid rgba(0, 82, 162, 0.25);
                        background: $white;
                        height: 40px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #464646;
                    }
                    .esim-not-installed{
                        background: transparent;
                        margin: 2px 0px;
                        box-shadow: none;
                    }
                    .buttons-ul{
                        li{
                            button{
                                width: auto;
                                padding: 8px 2rem;
                            }
                        }
                    }
                }
                .useful-buttons{
                    margin: 0px;
                    padding: 0px;
                    li{
                        list-style: none;
                        button{
                            border-radius: 15px;
                            border: 0.5px solid $btnprimary;
                            background: rgba(255, 255, 255, 0.10);
                            width: 100%;
                            padding: 10px 1.2rem;
                            .p-button-label{
                                font-size: 16px;
                                font-weight: 600;
                                color: $btnprimary;
                            }
                            .p-button-icon{
                                color: $btnprimary;
                            }
                            &:hover{
                                background: $btnprimary;
                                border: 0.5px solid $btnprimary;
                                .p-button-label{
                                    color: $white;
                                }
                                .p-button-icon{
                                    color: $white;
                                }
                            }
                        }
                    } 
                }
            }
            .view-details-btn{
                padding: 8px 15px;
                box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.10);
                background: $btnprimary;
                border: none;
                margin-top: 1rem;
                border-radius: 50px;
                width: 100%;
                max-width: 200px;
                .p-button-label{
                    font-size: 15px;
                    font-family: $font-base;
                    font-weight: 500;
                }
                &:hover{
                    background: $btnprimaryover;
                }
                  
            }
        }
        .data-info{
            position: relative;
            margin-bottom: 1rem;
            button{
                background: transparent;
                border: none;
                .p-button-label{
                    font-size: 16px;
                    font-weight: 500;
                    color: $secondary;
                }
                .p-button-icon{
                    color: $secondary; 
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .custom-dropdown{
                position: absolute;
                right: 0px;
                bottom: 5px;
            }
            .data-span{
                color: $teritary;
                font-size: 12px;
                position: absolute;
                margin: 0 auto;
                left: 0px;
                right: 0px;
                bottom: 10px;
                b{
                    font-size: 24px;
                    display: block;
                    line-height: 40px;
                    &.unlimited{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

// Responsive CSS
@media (max-width:768px){
    .contact-form-section{
        .form-box{
            .esims-section{
                .view-details-btn{
                    margin-top: 0rem;
                }
            }
        }
    }
}