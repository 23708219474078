@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $btnprimary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimaryover;
    }
  }
  
.marginTop {
    margin-top: 16px;
}
.error-message {
    color: $error-msg;
    font-size: 13px;
    margin: 5px 2px;
    text-align: left;
}

.loading-div{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.64);
    position: fixed;
    display: flex;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 999;
    align-items: center;
    justify-content: center;
    .pi-spin{
        width: 50px;
        height: 50px;
        color: #fff;
        font-size: 50px;
    }
}

.inner-header-section{
    &.voucher-header{
        min-height:550px;
        .header-text{
            color: $white;
            width: 100%;
            max-width: 1080px;
            margin: 6rem auto 0rem;
            h1{
                font-size: 56px;
                font-weight: 500;
                margin: 0px;
            }
            p{
                font-size: 20px;
                font-family: $font-base;
                font-weight: 500;
                line-height: 36px;
                margin: 25px auto;
                width: 100%;
            }
        }
        .is-sticky .fixed-height {
            height: 70px;
        }
    }
}
.voucher-section{
    padding: 40px 165px;
    margin-bottom: 10rem;
    .vouchers-box{
        border-radius: 30px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        border: 1px solid $input-border;
        width: 100%;
        max-width: 820px;
        margin: 0 auto;
        margin-top: -10rem;
       padding: 40px 40px;
       .box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            border: 1px solid #E5E5E5;
            background: #E8F3FF;
            padding: 20px 15px;
            color: $black;
            .icon{
                border-radius: 12px;
                background: $secondary;
                display: flex;
                width: 65px;
                height: 65px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .pi{
                    color: $white;
                    font-size: 30px;
                }
            }
            .text{
                text-align: left;
                h2{
                    margin: 0px;
                    font-size: 20px !important;
                    font-style: normal;
                    line-height: 140%;
                }
            }
            .pi-arrow-right{
                color: #7DA2E8;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.5s ease-out;
                font-size: 20px;
            }
            &:hover{
                background: $btnprimary;
                cursor: pointer;
                .icon{
                    background: $white;
                    .pi{
                        color: $btnprimary;
                    }
                }
                .text{
                    h2{
                        color: $white !important;
                    }
                }
                .pi-arrow-right{
                    transform: rotate(-30deg);
                    color: $white;
                }
            }
       }
       .form-wrapper{
        .input-field{
            position: relative;
            .p-inputtext{
                border-radius: 12px;
                border: 1px solid $input-border;
                background: $input-field;
                font-size: 18px;
                font-weight: 500;
                color: #212121;
                font-family: $font-base;
                padding: 18px 20px;
            }
            .arrow-btn{
                position: absolute;
                background: none;
                border: none;
                right: 7px;
                top: 7px;
                transition: all 0.2s ease-out;
                .p-button-icon{
                    color: $black;
                }
                &:hover{
                    right: 2px;
                }
            }
        }
        .details-btn{
            border: none;
            border-radius: 30px;
            padding: 16px 50px;
            background: $btnprimary;
            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
            margin-top: 1.5rem;
            .p-button-label{
                font-size: 16px;
                font-weight: 500;
            }
            &:hover{
                background: $btnprimaryover;
            }
        }
       }
       .topup-esim{
        h2{
            color: #212121;
            font-size: 26px;
            font-weight: 500;
            margin: 0px 0px 32px;
        }
        .esim-list{
            border-radius: 10px;
            border: 1px solid #E5E5E5;
            background: #FBFDFF;
            padding: 20px 25px;
            margin-bottom: 30px;
            .left{
                text-align: left;
                p{
                    margin: 0px;
                    font-size: 18px;
                    font-weight: 500;
                    color: $black;
                    margin-bottom: 10px;
                }
                span{
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .right{
                .topup-btn{
                    border: none;
                    border-radius: 30px;
                    background: $btnprimary;
                    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
                    padding: 13px 22px;
                    width: 170px;
                    .p-button-label{
                        font-size: 15px;
                        font-weight: 500;
                    }
                    &:hover{
                        background: $btnprimaryover;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0px;
            }
        }
       }
       .voucher-success-section{
            padding: 20px 80px;
            .sucess{
                border-radius: 12px;
                border: 1px solid #CBE0CC;
                background: #EEF7EE;
                padding: 17px 20px;
                display: flex;
                align-items: center;
                gap: 15px;
            }
            .qr-code{
                text-align: center;
                margin: 10px auto 4px;
                .p-image{
                    border-radius: 10px;
                    border: 5px solid #000;
                    display: inline-block;
                    padding: 40px;
                }
                p{
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    font-family: $font-secondary;
                    width: 100%;
                    max-width: 365px;
                    margin: 20px auto;
                }
            }
            .voucher-buttons{
                border: none;
                border-radius: 30px;
                background: $btnprimary;
                box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
                padding: 12px 50px;
                // width: 170px;
                .p-button-label{
                    font-size: 16px;
                    font-weight: 500;
                }
                &:hover{
                    background: $btnprimaryover;
                }
            }
       }
       &.bundle-details-section{
        margin-top: 2rem;
        .data-item-content{
            .title-description{
                border-bottom: 1px solid #D3DDE9;
                padding-bottom: 1.5rem;
                h2{
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 130%;
                    color: #191919;
                    span{
                        font-size: 22px;
                        font-weight: 700;
                    }
                }
            }
            table{
                margin-top: 1.5rem;
                tbody{
                    tr{
                        display: flex;
                        justify-content: space-between;
                        margin: 5px 0px;
                        padding: 12px 15px;
                        border-radius: 5px;
                        background: #fafdff;
                        font-family: $font-secondary;
                        td{
                            font-size: 18px;
                            &:first-child{
                                font-weight: 500;
                                color:$secondary;
                                position: relative;
                                padding-left: 1rem;
                                &::before{
                                    content: "";
                                    width: 6px;
                                    height: 6px;
                                    background: $secondary;
                                    border-radius: 10px;
                                    position: absolute;
                                    left: 0px;
                                    top: 10px;
                                }
                            }
                            .networkData{
                                .catalogNetworks{
                                    display: block;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        &:last-child{
                            background: transparent;
                            text-align: center;
                        }
                        .voucher-button{
                            border-radius: 50px;
                            background:$btnprimary;
                            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
                            padding: 12px 26px;
                            border: none;
                            width: 100%;
                            margin-top: 1rem;
                            .p-button-label{
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 180%; /* 32.4px */
                            }
                            &:hover{
                                background: $btnprimaryover;
                            }
                        }
                    }
                }
            }
        }
       }
    }
}

// Responsive CSS
@media (max-width:960px){
    .inner-header-section{
        &.voucher-header{
            min-height: 425px;
            .header-text{
                margin-top: 3rem;
                h1{
                    font-size: 36px;
                }
                p{
                    font-size: 16px;
                    padding: 0px 10px;
                    line-height: 30px;
                }
            }
        }
    }
    .voucher-section{
        padding: 20px;
        margin-bottom: 4rem;
        .vouchers-box{
            padding: 15px;
            border-radius: 15px;
            margin-top: -4rem;
            .box{
                padding: 12px;
                .icon{
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    .pi{
                        font-size: 20px;
                    }
                }
                .text h2{
                    font-size: 18px;
                    line-height: 24px;
                }
                .pi-arrow-right{
                    font-size: 16px;
                }
            }
            .form-wrapper .details-btn{
                margin-top: 1.3rem;
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .voucher-section {
        padding: 40px 20px;
    }
}
@media (min-width:1200px) and (max-width:1440px){
    .inner-header-section{
        &.voucher-header{
            .is-sticky .fixed-height {
                height: 90px;
            }
        }
    }
}
@media (max-width:768px){
    .voucher-section .vouchers-box{
        .voucher-success-section{
            padding: 20px 10px;
            .voucher-buttons{
                padding: 12px 35px;
            }
        }
        .topup-esim{
            .esim-list{
                .flex{
                    flex-direction: column;
                    align-items: start !important;
                }
                .right{
                    text-align: left;
                    .topup-btn{
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
@media (max-width:460px){
    .voucher-section .vouchers-box{
        .voucher-success-section{
            .voucher-btns-section{
                flex-direction: column;
                .voucher-buttons{
                    width: 100%;
                }
            }
            .qr-code .p-image{
                padding: 30px;
            }
        }
        &.bundle-details-section{
            .data-item-content{
                .title-description{
                    flex-direction: column;
                    align-items: start !important;
                    gap:10px;
                }
                table tbody tr{
                    flex-direction: column;
                    td{
                        &:last-child{
                            text-align: left !important;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
}