@import '../../assets/scss/variables';

// **** Privacy Policy Page CSS **** //
.privacy-policy-section{
    .content-table{
        width: 100%;
        border: 1px solid $primary;
        border-collapse: collapse;
        margin: 1.5rem 0px;
        thead{
            tr{
                th{
                    background: $primary;
                    border: 1px solid $primary;
                    padding: 20px 15px;
                    font-size: 16px;
                    font-weight: 500;
                    color: $white;
                    &.min-width{
                        width: 285px;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    border: 1px solid #E7EBFF;
                    padding: 10px 15px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    &::before{
                        content: attr(data-label);
                        display: inline-block;
                        line-height: 1.5;
                        margin-left: -100%;
                        width: 100%;
                        white-space: nowrap;
                    }
                    ol{
                        padding: 0px 15px;
                        margin-top: 0px !important;
                        li{
                            margin-bottom: 5px;
                            font-weight: 400;
                            &::before{
                                content: counter(item) !important;
                                left: 2px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-p{
        span{
            display: block;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;
            position: relative;
            padding-left: 2rem;
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 10px;
                background: $white;
                border: 1px solid $secondary;
                left: 15px;
                top: 8px;
            }
        }
    }
}