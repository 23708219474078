@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 16px 32px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 200px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  } 
.pricing-page{
    .custom-container{
        display: none;
    }
    .inner-header-section.aboutus-header-section{
        padding: 0rem;
        min-height: 0px;
    }
}
.inner-header-section{
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            max-width: 100%;
        }
    }
    &.aboutus-header-section{
        background: $white;
        background: url("../../assets/images/map.png") no-repeat center;
        background-size: contain;
        padding: 0rem 0px 1rem;
        min-height: 495px;
        background-size: 42% !important;
        .header-navigation{
            background: $primary;
            padding: 15px 165px;
        }
        .custom-container{
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0rem 3rem 3rem;
            h1{
                color: $black;
                margin-bottom: 1rem;
                font-size: 55px;
            }
            p{
                margin: 0px;
                color: $black;
                font-family: $font-secondary;
                // font-size: 32px;
                margin: 10px 0.8rem;
            }
            a{
                border-bottom: 1px solid $secondary;
                color: $secondary;
                font-family: $font-secondary;
                padding-bottom: 5px;
            }
        }
        .innerpage-header-text{
            position: relative;
            top: 10rem;
            padding-bottom: 6rem;
        }
    }
}
.is-sticky{
    .header-navigation{
        padding: 0px !important;
    }
}
.about-blocks-section{
    position: relative;
    .custom-container{
        padding: 4rem 1rem 1rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        h2{
            font-size: 32px;
            font-weight: 700;
            color: $black;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-weight: 400;
            font-family: $font-secondary;
            line-height: 36px;
        }
        .about-img{
            img{
                width: 100%;
                max-width: 510px;
                border-radius: 20px
            }
        }
        .right{
            padding-right: 6rem;
        }
        .custom-margin{
            margin-bottom: 5rem;
        }
    }
    &::before{
        content: '';
        position: absolute;
        background: url('../../assets/images/logo-shape-right.png') top right no-repeat;
        width: 250px;
        right: 0px;
        top: 12%;
        height: 495px;
        background-size: 75%;
    }
    &::after{
        content: '';
        position: absolute;
        background: url('../../assets/images/logo-shape.png') bottom left no-repeat;
        width: 250px;
        left:0px;
        bottom: 0%;
        height: 495px;
        background-size: 75%;
    }
}

// Responsive CSS
@media (min-width:1200px) and (max-width:1460px){
    .inner-header-section{
        &.aboutus-header-section{
            background-size: 800px !important;
            .header-navigation{
                padding: 10px 40px;
            }
            .custom-container {
                p{
                    font-size: 25px;
                }
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
    .about-blocks-section{
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
    .about-partners-section{
        padding: 0rem 40px 5rem;
        .partners-text-box{
            left: 2rem;
        }
        .option-box{
            right: 2rem;
            position: relative;
        }
    }
}
@media (max-width:960px){
    .inner-header-section{
        &.aboutus-header-section{
            background-size: 545px !important;
            background-position: center 5rem !important;
            min-height: auto;
            .header-navigation{
                padding: 5px 20px;
            }
            .custom-container{
                padding: 2rem 2rem 0rem;
                .contact-info-block{
                    margin-top: 2rem;
                    a{
                        margin-bottom: 10px;
                        display: inline-block;
                    }
                    h3{
                        font-size: 18px;
                    }
                }
                h1{
                    font-size:28px;
                }
                p{
                    font-size:22px;
                }
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
    .about-blocks-section{
        &::before,&::after{
            display: none;
        }
        .custom-container{
            padding: 0rem 2rem 0rem;
            h2{
                font-size: 22px;
            }
            .right{
                padding-right: 0rem;
            }
            p{
                font-size:18px;
                line-height: 32px;
            }
            .custom-margin{
                margin-bottom: 1rem;
                &.column-reverse{
                    flex-direction: column-reverse;
                }
            }
        }
    }
    .about-partners-section{
        padding: 0rem 20px 3rem;
        .partners-text-box{
            left: 0px;
            h2{
                font-size: 26px;
            }
            p{
                font-size: 16px;
                margin: 0px 0px 25px;
            }
        }
    }
}
@media (min-width:768px) and (max-width:960px){
    .about-blocks-section{
        .custom-container{
            .custom-margin{
                &.column-reverse{
                    flex-direction: row;
                }
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .about-partners-section{
        padding: 0rem 20px 3rem;
        .partners-text-box{
            left: 0px;
            h2{
                font-size: 26px;
            }
            p{
                font-size: 16px;
                margin: 0px 0px 25px;
            }
        }
    }
    .about-blocks-section{
        &::before,&::after{
            display: none;
        }
        .custom-container{
            padding: 3rem 2rem 0rem;
            h2{
                font-size: 22px;
            }
            .right{
                padding-right: 0rem;
            }
            p{
                font-size:18px;
                line-height: 32px;
            }
            .custom-margin{
                margin-bottom: 1rem;
            }
        }
    }
    .inner-header-section{
        &.aboutus-header-section{
            min-height: auto;
            .header-navigation{
                padding: 10px 20px;
            }
        }
        .header-text{
            margin-top: 0rem;
        }
    }
    .about-blocks-section{
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
}
@media (min-width:1461px) and (max-width:1750px){
    .inner-header-section{
        &.aboutus-header-section{
            .header-navigation{
                padding: 15px 40px;
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
}