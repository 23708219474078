@import '../../assets/scss/variables';


// **** Home Page CSS **** //
@mixin button { 
    padding: 24px 28px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $btnprimary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimaryover;
    }
} 

.header-section{
    // padding: 15px 165px;
    min-height: 950px;
    background-size: cover !important;
    background-position: right;
    .header-navigation{
        padding: 15px 165px;
        background:$primary;
    }
    .header-text{
        padding: 15px 165px;
        .header-text-box{
            border-radius: 30px;
            padding: 60px 50px;
            color: $white;
            margin-top: 12rem;
            position: relative;
            h1{
                font-size: 48px;
                font-weight: 700;
                margin: 0px;
                line-height: 65px;
                position: relative;
            }
            p{
                font-size: 24px;
                font-family: $font-secondary;
                font-weight: 500;
                line-height: 40px;
                margin: 10px 0px 0px;
                position: relative;
            }
            .goto-plans-button{
                @include button;
            }
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                background: $primary;
                border-radius: 30px;
                opacity: 0.8;
            }
        }
    }
}
.options-section{
    position: relative;
    top: -8rem;
    padding: 0px 165px;
    .option-box{
        background: $quaternary;
        border-radius: 30px;
        padding: 60px 30px;
        text-align: center;
        box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.05);
        margin: 0px 40px 0px 0px;
        height: 92%;
        h2{
            margin: 35px 0px 15px;
            font-size: 28px;
            font-weight: 600;
        }
        p{
            font-size: 20px;
            margin: 0px 0px 15px;
            font-family: $font-secondary;
            line-height: 30px;
            opacity: 0.6;
        }
    }
}
.offer-section{
    padding: 5rem 165px;
    background: $secondary;
    color: $white;
    background-image: url(../../assets/images/logo-shape.png);
    background-repeat: no-repeat;
    background-position: center left;
    h2{
        font-size: 48px;
        line-height: 65px;
        margin: 0px;
    }
    p{
        font-size: 18px;
        font-family: $font-secondary;
        line-height: 32px;
    }
    .choose-plans-button{
        @include button;
        background: $white;
        color: $secondary;
        &:hover{
            background: $btnprimaryover;
            color: $white;
        }
    }
    .hand-iphone-img{
        img{
            width: 100%;
            max-width: 650px;
            border-radius: 20px;
        }
    }
}
.plan-section{
    padding: 5rem 165px;
    background: $quaternary;
    h2{
        font-weight: 700;
        font-size: 38px;
    }
    .esim-icon{
        margin-left: 4rem;
        img{
            width: 100px;
        }
    }
    .bundles-section{
        position: relative;
        margin-top: 6rem;
        .plan-box{
            border-radius: 30px;
            border: 2px solid $quaternary !important;
            background: $white;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.02);
            font-family: $font-base;
            margin: 1rem;
            padding: 2rem;
            height: 95%;
            h3{
                margin: 0px;
                font-size: 21px;
                font-weight: 500;
                color: $primary;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            h2{
                font-size: 36px;
                font-weight: 600;
                color: $primary;
                margin: 0px 0px;
            }
            p{
                font-size: 12px;
                color: $secondary;
                display: block;
                margin-bottom: 20px;
                span{
                    font-size: 18px;
                    opacity: 0.6;
                    color: $primary;
                    font-family: $font-secondary;
                    margin-left: 15px;
                }
                &.info-text{
                    margin: 23px 0px 5px;
                    font-size: 16px;
                    color: $primary;
                    line-height: 25px;
                }
            }
            .goto-plans-button{
                @include button;
                padding:15px 20px;
                border: 1px solid $btnprimary;
                background: $white;
                box-shadow: none;
                max-width: 100%;
                width: 100%;
                .p-button-label{
                    color: $secondary;
                }
            }
            &:hover{
                border: 2px solid $btnprimary !important;
                .goto-plans-button{
                    background: $btnprimary;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
            .pi-eye{
                background: #f1f4f9;
                padding: 10px;
                border-radius: 24px;
                cursor: pointer;
                color: $teritary;
                &:hover{
                    background: $secondary;
                    color: $white;
                }
            }
            .country-image{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                min-width: 60px;
                min-height: 60px;
                overflow: hidden;
                border: 1px solid #f1f4f9;
                .flag{
                    font-size: 5.1rem;
                    position: relative;
                    top: -18px;
                    left: -12px;
                }
            }
        }
        .p-carousel-container{
            .p-link{
                width: 50px;
                height: 50px;
                border: 1px solid $teritary;
                background: $teritary;
                position: absolute;
                margin: 0 auto;
                left: 0px;
                right:0px;
                top:-65px;
                .p-carousel-next-icon{
                    width: 18px;
                    height: 18px;
                    color: $white;
                }
                .p-carousel-prev-icon{
                    color: $white;
                }
                &:hover{
                    background: $secondary;
                    border: 1px solid $secondary;
                    .p-carousel-next-icon{
                        color: $white;
                    }
                }
                &.p-carousel-prev{
                    left: -65px;
                    z-index: 1;
                }
                &.p-carousel-next{
                    right: -65px;
                    z-index: 1;
                }
                &.p-disabled{
                    background: $quaternary;
                    opacity: 0.75;
                    .p-carousel-prev-icon{
                        color: $teritary;
                    }
                    .p-carousel-next-icon{
                        color: $secondary;
                    }
                }
            }
            .p-carousel-items-content{
                margin-top: 0rem;
            }
        }
        .p-carousel-indicators{
            display: none;
            .p-carousel-indicator{
                .p-link{
                    border-radius: 5px;
                }
                &.p-highlight{
                    .p-link{
                        background: $secondary;
                    } 
                }
            }
        }
        .country-dropdown{
            position: absolute;
            right: 16px;
            top: -105px;
            z-index: 10;
            .label-span{
                font-weight: 600;
                color: $teritary;
                font-size: 18px; 
                margin-bottom: 5px;
                display: block;
                text-align: center;
            }
            .p-dropdown{
                padding: 6px 25px;
                border-radius: 50px;
                background: $secondary;
                margin: 0px 0px;
                border: none;
                width: 100%;
                max-width: 325px;
                min-width: 325px;
                .p-dropdown-label{
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 600;
                    color: $white;
                }
                .p-dropdown-trigger{
                    padding: 0px;
                    width: 1rem;
                    .p-dropdown-trigger-icon{
                        color: $white;
                    }
                }
            }
            .country-image{
                width: 22px;
                height: 22px;
                border-radius: 50%;
                min-width: 22px;
                min-height: 22px;
                overflow: hidden;
                margin-right: 5px;
                .flag{
                    object-fit: cover;
                    width: 23px !important;
                    height: 23px !important;
                    font-size: 30px;
                    position: relative;
                    top: -5px;
                    left: -4px;
                }
                .flag-img{
                    object-fit: cover;
                    width: 25px !important;
                    height: 23px !important;
                    position: relative;
                    top: 0px;
                    left: 0px;
                }
            }
        }
        .skeleton-loading-section{
            display: flex;
        }
    }
}
.benefits-section{
    padding: 2rem 165px;
    background: $quaternary;
    h2{
        font-size: 48px;
        font-weight: 700;
    }
    .benefits-img{
        margin-top: 2rem;
        display: block;
    }
    .benefits-icon{
        min-width: 100px;
        height: 100px;
        border-radius: 50%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .benefits-text{
        color: $black;
        h3{
            font-size: 20px;
            font-weight: 600;
            margin: 0px 0px 12px;
        }
        p{
            font-size: 18px;
            font-family: $font-secondary;
            opacity: 0.6;
            line-height: 30px;
            margin: 0px;
        }
    }
}
.partners-section{
    padding: 2rem 165px;
    background: $quaternary;
    .partners-text-box{
        text-align: center;
        background: $white;
        border-radius: 40px;
        color: $primary;
        min-height: 645px;
        position: relative;
        z-index: 1;
        padding: 0px 2rem;
        h2{
            margin: 0px 0px 35px;
            font-size: 48px;
            font-weight: 700;
        }
        p{
            font-size: 24px;
            margin: 0px 0px 60px;
            font-weight: 500;
        }
        .goto-plans-button{
            @include button;
        }
    }
    .benefits-img{
        position: relative;
        left: -8rem;
    }
}
.review-section{
    padding:3rem 165px;
    background: $quaternary;
    h2{
        font-weight: 700;
        font-size: 48px;
        text-align: center;
    }
    .reviews-box{
        .reviews-list{
            border-radius: 8px;
            border: 2px solid $quaternary !important;
            background: $white;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.02);
            font-family: $font-base;
            min-height: 406px;
            padding: 2rem 3rem;
            margin: 1rem;
            ul{
                margin: 0px 0px 15px;
                padding: 0px;
                li{
                    list-style: none;
                    display: inline-block;
                    margin-right: 5px;
                    .p-image{
                        img{
                            width: 20px;
                        }
                    }
                }
            }
            p{
                font-size: 22px;
                color: $primary;
                font-family: $font-secondary;
                opacity: 0.8;
                line-height: 40px;
                margin: 0px 0px 15px;
            }
            .avatar-text{
                h4{
                    font-size: 17px;
                    color: $primary;
                    margin: 0px 0px 6px;
                    font-weight: 600;
                }
                span{
                    font-size: 16px;
                    font-weight: 400;
                    opacity: 0.4;
                    color: $primary;
                }
            }
        }
    }
    .p-carousel-container{
        position: relative;
        .p-link{
            position: absolute;
            margin: 0 auto;
            left: 0px;
            right:0px;
            border: 1px solid $secondary;
            background: $secondary;
            width: 50px;
            height: 50px;
            top: 0px;
            .p-icon{
                width: 20px;
                height: 20px;
                color: $white;
            }
            &:hover{
                background: $primary;
                border: 1px solid $primary;
                .p-icon{
                    color: $white;
                }
            }
            &.p-carousel-prev{
                left: -65px;
                z-index: 1;
            }
            &.p-carousel-next{
                right: -65px;
                z-index: 1;
            }
            &.p-disabled{
                background: $quaternary;
                opacity: 0.9;
                .p-icon{
                    color: $secondary;
                }
            }
        }
        .p-carousel-items-content{
            margin-top: 5rem;
        }
    }
    .p-carousel-indicators{
        .p-carousel-indicator{
            .p-link{
                border-radius: 25px;
                background-color: #DCDEFF;
                width: 12px;
                height: 12px;
            }
            &.p-highlight{
                .p-link{
                    background: $secondary;
                } 
            }
        }
    }
}
.faq-section, .partners-section{
    padding: 0rem 165px 4rem;
    background: $quaternary;
    h2{
        margin: 0px 0px 40px;
        font-size: 48px;
        font-weight: 700;
        text-align: center;
    }
    .p-accordion{
        margin: 0px 75px;
        .p-accordion-tab{
            margin-bottom: 20px;
            .p-accordion-header{
                .p-accordion-header-link{
                    border-radius: 100px;
                    background: $white;
                    border: 1px solid $quaternary;
                    font-size: 22px;
                    font-weight: 500;
                    padding: 28px 90px 28px 40px;
                    // color: $primary;
                    .p-accordion-toggle-icon{
                        position: absolute;
                        right: 4px;
                        width: 56px;
                        height: 56px;
                        background: #F7F8FA;
                        border-radius: 50px;
                        color: $teritary;
                        border: 1px solid $quaternary;
                        padding: 18px;
                    }
                    .p-accordion-header-text{
                        line-height: 30px;
                        font-size: 20px;
                        font-family: $font-base;
                    }
                }
            }
            .p-toggleable-content{
                margin-top: 0rem;
                .p-accordion-content{
                    border-radius: 50px;
                    background: transparent;
                    margin-bottom: 1rem;
                    padding: 35px 40px;
                    border: 1px solid #ededed;
                    p{
                        font-size: 18px;
                        font-family: $font-base;
                        line-height: 26px;
                        text-align: left;
                        a{
                            text-decoration: underline;
                            color: $secondary;
                        }
                    }
                    ol, ul{
                        li{
                            font-size: 17px;
                            font-family: $font-base;
                            line-height: 36px;
                            text-align: left; 
                        }
                    }
                    .faq-images{
                        display: block;
                        margin: 1.5rem 0px;
                        width: 50%;
                        &.size1{
                            max-width: 196px;
                        }
                        &.size2{
                            max-width: 422px;
                        }
                    }
                }
            }
        }
    }
    .faq-custom-margin{
        margin: 2.5rem 0px;
    }
    .view-all-button{
        @include button;
        margin: 2rem auto 1rem;
        text-align: center;
        color: $white;
        font-size: 18px;
        font-weight: 600;
        padding: 24px 30px;
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1460px){
    .options-section, .plan-section, .benefits-section, .partners-section, .faq-section, .review-section{
        padding: 10px 40px;
    }
    .offer-section{
        padding: 3rem 40px;
    }
    .header-navigation, .header-section .header-text{
        padding: 10px 40px !important;
    }
    .is-sticky{
        .fixed-height{
            height: 90px;
        }
        header{
            padding: 10px 40px;
        }
    }
    .header-section{
        min-height: 760px !important;
        .header-text{
            .header-text-box{
                padding: 50px 30px;
                margin-top: 8rem;
                h1{
                    font-size: 40px;
                    line-height: 60px;
                }
            }
        }
    }
    .options-section{
        .option-box{
            padding: 50px 20px;
            margin: 0px 20px 0px 0px;
            height: 100%;
            p{
                font-size: 16px;
            }
        }
    }
    .offer-section{
        background-size: 185px;
        h2{
            font-size: 40px;
            line-height: 60px;
        }
    }
    .plan-section, .review-section, .faq-section{
        h2{
            font-size: 40px;
        }
    }
    .plan-section{
        .bundles-section{
            .plan-box{
                margin: 0.4rem;
                padding: 2rem 1rem;
            }
        }
    }
    .review-section{
        .reviews-box{
            .reviews-list{
                padding: 2rem 2rem;
                margin: 0.7rem;
                p{
                    line-height: 32px;
                    font-size: 16px;
                }
            }
        }
    }
    .benefits-section{
        h2{
            font-size: 40px;
        }
        .option-box{
            .p-image{
                img{
                    width: 100%;
                }
            }
        }
    }
    .partners-section{
        .partners-text-box{
            min-height: 490px;
            padding: 0px 1rem;
            h2{
                font-size: 40px;
            }
        }
        .option-box{
            .benefits-img{
                left: -3rem;
                img{
                    width: 100%;
                }
            }
        }
    }
}
.bundle-dialog{
    .p-dialog-title{
        color: $teritary;
        font-size: 24px;
    }
    .p-dialog-content{
        scrollbar-color: $teritary;
        scrollbar-width: thin;
        .data-item-header{
            h3{
                margin: 5px 0px;
                &.color-h3{
                    color: $teritary;
                }
            }
        }
        .data-item-content{
            table{
                tbody{
                    tr{
                        display: flex;
                        justify-content: space-between;
                        margin: 5px 0px;
                        padding: 12px 15px;
                        border-radius: 5px;
                        background: #fafdff;
                        font-family: $font-secondary;
                        td{
                            .networkData{
                                text-align: right;
                                .catalogNetworks{
                                    display: block;
                                    margin-bottom: 10px;
                                    font-size: 15px;
                                }
                            }
                            .roamingData{
                                .roaming-tooltip{
                                    position: relative;
                                    top: 2px;
                                    left: 5px;
                                    margin-right: 5px;
                                    cursor: pointer;
                                    &:hover{
                                        background: $secondary;
                                        color: $white;
                                        border-radius: 10px;
                                    }
                                }
                            }
                            &:first-child{
                                font-weight: 500;
                                color: $primary;
                                position: relative;
                                padding-left: 1rem;
                                &::before{
                                    content: '';
                                    width: 6px;
                                    height: 6px;
                                    background: $secondary;
                                    border-radius: 10px;
                                    position: absolute;
                                    left: 0px;
                                    top: 8px;
                                }
                            }
                        }
                        &.last-row{
                            background: transparent;
                            padding: 10px 0px;
                            margin: 0px;
                            td{
                                padding-left: 0px;
                                &::before{
                                    display: none;
                                }
                                .buynow-btn{
                                    @include button;
                                    padding: 15px 40px;
                                    margin-top: 0rem;
                                    min-width: 220px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .roamingColumnsDialog{
            grid-template-columns: auto auto auto auto;
            display: grid;
            ul{
                li{
                    margin: 15px 0px;
                    font-size: 15px;
                    font-family: $font-secondary;
                    &::marker{
                        color: $secondary;
                    }
                }
            }
        }
        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: $primary;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $teritary;
            border-radius: 10px;
        }
    }
}
@media (max-width:960px){
    @mixin button { 
        padding: 10px 15px;
        max-width: 220px;
        .p-button-label{
            font-size: 16px;
        }
    } 
    .header-section, .options-section, .offer-section, .plan-section, .benefits-section, .faq-section, .partners-section,.review-section{
        padding: 25px 20px;
    }
    .is-sticky{
        header{
            padding: 5px 20px;
        }
        .fixed-height {
            height: 68px;
        }
    }
    .header-navigation{
        padding: 5px 20px !important;
    }
    .header-section{
        min-height: auto !important;
        padding: 0px;
        .header-text{
            padding: 5px 20px;
            .header-text-box{
                padding: 20px;
                margin-bottom: 5rem;
                margin-top: 60px !important;
                h1{
                    padding-right: 0px;
                    font-size: 28px;
                    line-height: 40px;
                }
                p{
                    padding-right: 0px;
                    font-size: 18px;
                    line-height: 30px;
                }
                .goto-plans-button{
                    @include button;
                    max-width: 250px;
                }
            }
        }
    }
    .options-section{
        top: -5rem;
        .option-box{
            padding: 20px;
            margin: 0px;
            h2{
                font-size: 22px;
                margin: 20px 0px 15px;
            }
            p{
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    .offer-section{
        background-image: none;
        .hand-iphone-img{
            img{
                width: 100%;
                max-width: 100%;
            }
        }
        h2{
            font-size: 26px;
            line-height: 40px;
        }
        p{
            font-size: 16px;
            line-height: 26px;
        }
        .choose-plans-button{
            @include button;
            max-width: 250px;
        }
        .column-reverse{
            flex-direction: column-reverse;
        }
    }
    .plan-section{
        h2{
            font-size: 26px;
            line-height: 40px;
        }
        .esim-icon{
            margin-left: 1.5rem;
            img{
                width: 40px;
            }
        }
       .bundles-section{
            margin-top: 1rem;
            .country-dropdown{
                position: inherit;
                right: auto;
                top: auto;
                text-align: center;
                .label-span{
                    font-size: 16px;
                    margin-right: 0px;
                    margin-bottom: 8px;
                    display: block;
                }
                .p-dropdown{
                    padding: 3px 15px;
                    width: 100%;
                    text-align: left;
                    .p-dropdown-label{
                        font-size: 16px;
                    }
                }
            }
            .plan-box{
                padding: 1.5rem;
                margin: 1rem 0rem;
                h3{
                    font-size: 18px;
                }
                h2{
                    font-size: 30px;
                }
                p{
                    span{
                        font-size: 16px;
                    }
                }
                .goto-plans-button{
                    @include button;
                    max-width: 100%;
                }
            }
            .p-carousel-container{
                .p-link{
                    top: auto;
                    bottom: -50px;
                    width: 40px;
                    height: 40px;
                }
            }
       }
    }
    .benefits-section{
        padding-top: 3rem;
        h2{
            font-size: 26px;
            line-height: 40px;
        }
        .benefits-text{
            h3{
                font-size: 16px;
                margin: 0px 0px 5px;
            }
            p{
                font-size: 16px;
                line-height: 25px;
            }
        }
        .benefits-icon{
            min-width: 80px;
            height: 80px;
            .hand-iphone-img{
                img{
                    width: 100%;
                    max-width: 45px;
                }
            }
        }
        .benefits-img{
            img{
                width: 100%;
            }
        }
    }
    .partners-section{
        padding-bottom: 0px;
        .partners-text-box{
            min-height: auto;
            padding: 2rem 1rem;
            h2{
                font-size: 26px;
                line-height: 40px;
                margin: 0px 0px 20px;
            }
            p{
                font-size: 16px;
                line-height: 25px;
                margin: 0px 0px 25px;
            }
            .view-all-button{
                @include button;
                font-size: 16px;
            }
        }
        .benefits-img{
            left: auto;
            top: -5rem;
            img{
                width: 100%;
            }
        }
    }
    .review-section{
        h2{
            font-size: 26px;
            line-height: 40px;
            margin: 0px 0px 0px;
        }
        .p-carousel-container{
            .p-link{
                width: 40px;
                height: 40px;
                top: 25px;
            }
        }
        .reviews-box{
            .reviews-list{
                min-height:auto;
                padding: 1rem 1rem;
                p{
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
        .p-carousel{
            .p-carousel-indicators{
                padding: 0px;
            }
        }
    }
    .faq-section{
        h2{
            font-size: 26px;
            line-height: 40px;
            margin: 0px 0px 15px;
        }
        .view-all-button{
            @include button;
            font-size: 16px;
            margin: 0rem auto 1rem;
        }
        .p-accordion{
            margin: 0px 0px;
            .p-accordion-tab{
                .p-accordion-header{
                    .p-accordion-header-link{
                        padding: 15px 60px 15px 20px;
                        font-size: 16px;
                        border-radius: 55px;
                        .p-accordion-toggle-icon{
                            width: 45px;
                            height: 45px;
                            padding: 15px;
                            right: -3px;
                        }
                        .p-accordion-header-text{
                            line-height: 24px;
                            font-size: 15px;
                        }
                    }
                }
                .p-toggleable-content{
                    .p-accordion-content{
                        padding: 40px 20px 30px;
                        p{
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    .header-navigation{
        .p-menubar{
            display: flex;
            .p-menubar-end{
                margin-top: 0px;
                margin-left: auto;
            }
        }
    }
    .options-section{
        .option-box{
            padding: 15px;
            min-height: 290px;
        }
    }
    .offer-section{
        .column-reverse{
            flex-direction: row;
        }
    }
    .plan-section{
        .bundles-section{
            .country-dropdown{
                text-align: right;
                .label-span{
                    margin-right: 25px;
                    display: inline-block;
                }
                .p-dropdown{
                    width: auto;
                }
            }
        }
        .bundles-section{
            .plan-box{
                margin: 1rem 1rem;
            }
        }
    }
    .partners-section{
        .benefits-img{
            top: 0rem;
            left: -1rem;
        }
        .partners-text-box{
            left: 1rem;
        }
    }
    .bundle-dialog{
        width: 90% !important;
    }
    .review-section{
        .reviews-box{
            .reviews-list{
                p{
                    font-size: 16px;
                }
            }
        }
    }
    .faq-section .p-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content .faq-images{
        width: 100%;
        max-width: 85%;
    }
}
@media (min-width:961px) and (max-width:1199px){
    .header-section, .options-section, .plan-section, .benefits-section, .faq-section, .partners-section,.review-section{
        padding: 25px 20px;
    }
    .is-sticky{
        header{
            padding: 10px 20px;
        }
    }
    .header-navigation{
        padding: 10px 20px !important;
    }
    .header-section{
        min-height: auto !important;
        padding:0px;
        .header-text{
            padding: 10px 20px;
            .header-text-box{
                padding: 20px;
                margin-bottom: 5rem;
                margin-top: 35px !important;
                h1{
                    padding-right: 0px;
                    font-size: 36px;
                    line-height: 45px;
                }
                p{
                    padding-right: 0px;
                    font-size: 18px;
                    line-height: 30px;
                }
                .goto-plans-button{
                    @include button;
                    max-width: 330px;
                }
            }
        }
    }
    .options-section{
        top: -5rem;
        .option-box{
            padding: 25px 20px;
            margin: 0px;
            min-height: 325px;
            h2{
                font-size: 24px;
            }
            p{
                font-size: 16px;
            }
        }
    }
    .offer-section{
        background-image: none;
        padding: 3rem 20px;
        h2{
            font-size: 36px;
            line-height: 50px;
        }
    }
    .plan-section{
        h2{
            font-size: 36px;
            line-height: 40px;
        }
        .esim-icon{
            margin-left: 1.5rem;
            img{
                width: 40px;
            }
        }
       .bundles-section{
            margin-top: 4rem;
            .country-dropdown{
                .label-span{
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .p-dropdown{
                    padding: 3px 15px;
                    text-align: left;
                    .p-dropdown-label{
                        font-size: 16px;
                    }
                }
            }
            .plan-box{
                padding: 1.5rem;
                margin: 1rem 0.8rem;
                h3{
                    font-size: 18px;
                }
                h2{
                    font-size: 36px;
                }
                p{
                    span{
                        font-size: 16px;
                    }
                }
            }
            .p-carousel-container{
                .p-link{
                    top: auto;
                    bottom: -50px;
                    width: 40px;
                    height: 40px;
                }
            }
       }
    }
    .benefits-section{
        padding-top: 3rem;
        h2{
            font-size: 36px;
            line-height: 40px;
        }
        .benefits-text{
            h3{
                font-size: 16px;
                margin: 0px 0px 5px;
            }
            p{
                font-size: 16px;
                line-height: 25px;
            }
        }
        .benefits-icon{
            min-width: 80px;
            height: 80px;
            .hand-iphone-img{
                img{
                    width: 100%;
                    max-width: 45px;
                }
            }
        }
        .benefits-img{
            img{
                width: 100%;
            }
        }
    }
    .partners-section{
        padding-bottom: 0px;
        .partners-text-box{
            min-height: 450px;
            padding: 3rem 1rem;
            left:2rem;
            h2{
                font-size: 28px;
                line-height: 40px;
                margin: 0px 0px 20px;
            }
            p{
                font-size: 18px;
                line-height: 25px;
                margin: 0px 0px 45px;
            }
            .view-all-button{
                @include button;
                font-size: 16px;
            }
        }
        .benefits-img{
            left: -2rem;
            top: 0rem;
            img{
                width: 100%;
            }
        }
    }
    .review-section{
        h2{
            font-size: 36px;
            line-height: 40px;
            margin: 0px 0px 0px;
        }
        .p-carousel-container{
            .p-link{
                width: 40px;
                height: 40px;
                top: 25px;
            }
        }
        .reviews-box{
            .reviews-list{
                min-height: 295px;
                padding: 1rem 1rem;
                p{
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
        .p-carousel{
            .p-carousel-indicators{
                padding: 0px;
            }
        }
    }
    .faq-section{
        h2{
            font-size: 36px;
            line-height: 40px;
            margin: 0px 0px 15px;
        }
        .view-all-button{
            @include button;
            font-size: 16px;
            margin: 0rem auto 1rem;
        }
        .p-accordion{
            margin: 0px 0px;
            .p-accordion-tab{
                .p-accordion-header{
                    .p-accordion-header-link{
                        padding: 20px 50px 20px 20px;
                        font-size: 16px;
                        .p-accordion-toggle-icon{
                            width: 45px;
                            height: 45px;
                            padding: 15px;
                        }
                        .p-accordion-header-text{
                            line-height: 24px;
                        }
                    }
                }
                .p-toggleable-content{
                    .p-accordion-content{
                        padding: 40px 20px 30px;
                        p{
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    .bundle-dialog{
        width: 85% !important;
    }
}
@media (min-width:991px) and (max-width:1199px){
    .skeleton-loading-section{
        .medium-devices{
            width: 33.333%;
        }
    }
}
@media (max-width:768px){
    .bundle-dialog{
        width: 90% !important;
        .p-dialog-content{
            .data-item-header{
                h3{
                    &.color-h3{
                        text-align: left;
                    }
                }
            }
            .data-item-content{
                table{
                    tbody{
                        tr{
                            display: grid;
                            td{
                                &.text-right{
                                    text-align: left !important;
                                    margin-top: 5px;
                                }
                                .networkData{
                                    .catalogNetworks{
                                        text-align: left;
                                        margin-top: 5px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .roamingColumnsDialog{
                display: block !important;
            }
        }
    }
    .faq-section{
        .faq-custom-margin{
            margin: 1.5rem 0px;
        }
        .p-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content{
            ul{
                padding: 0px;
                margin-left: 15px;
            }
            .faq-images{
                width: 100%;
                &.size1{
                    width: 100%;
                    max-width: 38%;
                    border-radius: 5px;
                }
            }
        }
    }
    
}

@media (min-width:1461px) and (max-width:1750px){
    .options-section, .plan-section, .benefits-section, .partners-section, .faq-section, .review-section{
        padding: 15px 40px 0px;
    }
    .offer-section{
        padding: 3rem 140px;
    }
    .header-navigation{
        padding: 15px 40px !important;
    }
    .header-section .header-text{
        padding: 15px 40px;
    }
    .is-sticky{
        header{
            padding: 15px 40px;
        }
    }
    .faq-section{
        padding-bottom: 40px;
    }
    .partners-section .option-box .benefits-img{
        left: -3rem;
        img{
            width: 100%;
        }
    }
}
@media (max-width:400px){
    .faq-section .view-all-button{
        display: block;
        font-size: 15px;
    }
}
@media (max-width:1199px){
    .plan-section .bundles-section{
        padding-bottom: 4rem;
        .p-carousel-container{
            .p-link{
                bottom: 5px;
            }
        }
    }
}